// Copyright (C) 2018-2025 Skylark Drones

import Vue from 'vue'
import Router from 'vue-router'

import HomePage from '@/components/homepage/HomePage'
import SitesOverviewPage from '@/components/sites/SitesOverviewPage'

import {
  checkAuth,
  redirectLoggedInUser,
  redirectUsersToCorrectLivestreamPage
} from '@/auth'
import routesName from './routesName'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    beforeEnter: redirectLoggedInUser,
    meta: {
      requiresAuth: false,
      showSidebar: false
    }
  },
  {
    path: '/company/:companyId/home',
    name: 'LoggedInHomePage',
    component: HomePage,
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/usage',
    name: 'ProductUsageOverview',
    component: () =>
      import(
        /* webpackChunkName: "productUsagePage" */ '@/components/productusage/UsageOverview'
      ),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/governance',
    name: 'GovernanceOverview',
    component: () =>
      import(
        /* webpackChunkName: "governanceOverview" */ '@/components/governance/GovernanceOverview.vue'
      ),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/company/:companyId/livestream/:streamId',
    name: 'StreamPrivatePage',
    component: () =>
      import(
        /* webpackChunkName: "StreamPrivatePage" */ '@/components/streams/StreamPrivatePage'
      ),
    beforeEnter: redirectUsersToCorrectLivestreamPage,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/company/:companyId/streams',
    name: 'StreamOverviewPage',
    component: () =>
      import(
        /* webpackChunkName: "StreamOverviewPage" */ '@/components/streams/StreamOverviewPage'
      ),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/company/:companyId/streams/:streamId/recording',
    name: 'RecordingViewPage',
    component: () =>
      import(
        /* webpackChunkName: "RecordingViewPage" */ '@/components/streams/recording/RecordingViewPage'
      ),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/company/:companyId/livestream/:streamId/public',
    name: 'StreamPage',
    component: () =>
      import(
        /* webpackChunkName: "StreamPage" */ '@/components/streams/StreamPage'
      ),
    meta: {
      requiresAuth: false,
      showSidebar: false
    }
  },
  {
    path: '/company/:companyId/livestream/:streamId/host',
    name: 'StreamHostDevPage',
    component: () =>
      import(
        /* webpackChunkName: "StreamHostDevPage" */ '@/components/dev-tools/stream/StreamHostDevPage'
      ),
    meta: {
      requiresAuth: true,
      showSidebar: false
    }
  },
  {
    path: '/livestream/:streamId/public',
    name: 'StreamPageOld',
    component: () =>
      import(
        /* webpackChunkName: "StreamPage" */ '@/components/streams/StreamPage'
      ),
    meta: {
      requiresAuth: false,
      showSidebar: false
    }
  },
  {
    path: '/news',
    name: 'NewsOverview',
    component: () =>
      import(
        /* webpackChunkName: "newsPage" */ '@/components/news/NewsOverview'
      ),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: false,
      showSidebar: true
    }
  },
  {
    path: '/news-cms',
    name: 'NewsCMS',
    component: () =>
      import(/* webpackChunkName: "newsCmsPage" */ '@/components/news/NewsCMS'),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/tips-cms',
    name: 'TipsCMS',
    component: () =>
      import(/* webpackChunkName: "tipsCmsPage" */ '@/components/tips/TipsCMS'),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/company/:companyId/drones',
    name: 'DronesDisplay',
    component: () =>
      import(
        /* webpackChunkName: "dronesPage" */ '@/components/drone/DronesDisplay'
      ),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/company/:companyId/sites',
    name: 'SitesOverviewPage',
    component: SitesOverviewPage,
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/collections',
    name: 'CollectionsOverviewPage',
    component: () =>
      import(
        /* webpackChunkName: "collectionsOverviewPage" */ '@/components/collections/CollectionsOverviewPage'
      ),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/company/:companyId/collections/:collectionId',
    name: 'CollectionDetailsPage',
    component: () =>
      import('@/components/collections/CollectionDetailsPage.vue'),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/company/:companyId/sites/:siteId',
    name: 'SiteDetailsPage',
    component: () =>
      import(
        /* webpackChunkName: "siteDetailsPage" */ '@/components/sitedetails/SiteDetailsPage'
      ),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/users/:userId/:tabKey?',
    name: 'UsersDisplay',
    component: () =>
      import(
        /* webpackChunkName: "usersPage" */ '@/components/user/UserDetails/UserPage.vue'
      ),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/company/:companyId/batteries',
    name: 'BatteriesDisplay',
    component: () =>
      import(
        /* webpackChunkName: "batteriesPage" */ '@/components/battery/BatteriesDisplay'
      ),
    beforeEnter: checkAuth,
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '/airspace-advisory',
    name: 'AirspaceAdvisory',
    component: () =>
      import(
        /* webpackChunkName: "AirspaceAdvisoryPage" */ '@/components/airspace-advisory/AirspaceAdvisoryPage'
      ),
    meta: {
      requiresAuth: false,
      showSidebar: true
    }
  },
  {
    path: '/plans',
    name: 'PlansDetailsPage',
    beforeEnter: checkAuth,
    component: () =>
      import(
        /* webpackChunkName: "PlansDetailsPage" */ '@/components/plans/PlansDetailsPage'
      ),
    meta: {
      requiresAuth: false,
      showSidebar: false
    }
  },
  {
    path: '/drone-data-verification',
    name: 'DroneDataVerification',
    component: () =>
      import(
        /* webpackChunkName: "DroneDataVerificationPage" */ '@/components/drone-data-verification/DroneDataVerificationPage'
      ),
    meta: {
      requiresAuth: false,
      showSidebar: true
    }
  },
  {
    path: '/whatsnew',
    name: 'Changelog',
    component: () =>
      import(
        /* webpackChunkName: "changelog" */ '@/components/changelog/Changelog'
      ),
    meta: {
      requiresAuth: false,
      showSidebar: false
    }
  },
  {
    path: '/whatsnew/:version',
    name: 'ReleasePage',
    component: () =>
      import(
        /* webpackChunkName: "releasePage" */ '@/components/changelog/ReleasePage'
      ),
    meta: {
      requiresAuth: false,
      showSidebar: false
    },
    props: true
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () =>
      import(
        /* webpackChunkName: "forbiddenPage" */ '@/components/common/Forbidden'
      ),
    meta: {
      requiresAuth: false,
      showSidebar: false
    }
  },
  {
    path: '/ai-test-bench',
    name: 'AITestBench',
    component: () =>
      import(
        /* webpackChunkName: "AITestScreen" */ '@/components/streams/AITestScreen'
      ),
    meta: {
      requiresAuth: false,
      showSidebar: false
    }
  },
  {
    path: '/company/:companyId/settings/:tabKey?',
    name: routesName.SettingsPage,
    beforeEnter: checkAuth,
    component: () =>
      import(
        /* webpackChunkName: "OrganizationPage" */ '@/components/organization/OrganizationPage.vue'
      ),
    meta: {
      requiresAuth: true,
      showSidebar: true
    }
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () =>
      import(
        /* webpackChunkName: "pageNotFound" */ '@/components/common/PageNotFound'
      ),
    meta: {
      requiresAuth: false,
      showSidebar: false
    }
  }
]

const router = new Router({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
