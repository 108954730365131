// Copyright (C) 2023-2025 Skylark Drones

import WIND_TURBINE_MISSION from '@/assets/images/wind_turbine_mission.svg'
import CELL_TOWER_MISSION from '@/assets/images/cell_tower_mission.svg'
import VIDEO_MISSION from '@/assets/images/video_mission.svg'
import MAP_MISSION from '@/assets/images/map_mission.svg'
import WAYPOINT_MISSION from '@/assets/images/waypoint_mission.svg'
import POI_MISSION from '@/assets/images/poi_mission.svg'

export const POI_MISSIONS = process.env.VUE_APP_POI_MISSIONS === 'true'

export const MISSION_TYPES = [
  {
    text: 'Mapping',
    subtitle: 'Orthomosaic maps, DSM and DTM',
    dataType: 'RGB Images',
    aoiType: 'Area',
    icon: 'mapping-mission.png'
  },
  {
    text: 'Corridor Video',
    subtitle: 'Roadways, Railway Construction Monitoring applications',
    dataType: 'Video',
    aoiType: 'Corridor',
    icon: 'corridor-video-mission.png'
  }
]

export const NEW_MISSION_TYPES = [
  {
    text: 'Mapping',
    type: 'mapping',
    hint: 'Outputs include Orthomosaic maps, DSM and DTM of site',
    supportedSiteTypes: ['area', 'corridor'],
    execution: 'automatic',
    icon: MAP_MISSION
  },
  {
    text: 'Video',
    type: 'video',
    supportedSiteTypes: ['corridor'],
    hint: 'Outputs include video footage of site',
    execution: 'automatic',
    icon: VIDEO_MISSION
  },
  {
    text: 'Wind Turbine',
    type: 'wind-turbine',
    hint: 'Outputs include images of critical parts of wind turbine',
    supportedSiteTypes: ['asset'],
    execution: 'manual',
    icon: WIND_TURBINE_MISSION
  },
  {
    text: 'Cell Tower',
    type: 'cell-tower',
    hint: 'Outputs include images of critical parts of cell tower',
    supportedSiteTypes: ['asset'],
    execution: 'manual',
    icon: CELL_TOWER_MISSION
  },
  {
    text: 'Waypoints',
    type: 'waypoints',
    hint: 'Outputs include images or/and video footage of site',
    supportedSiteTypes: ['area', 'corridor', 'asset'],
    execution: 'automatic',
    icon: WAYPOINT_MISSION
  },
  ...(POI_MISSIONS
    ? [
        {
          text: 'POI',
          type: 'poi',
          hint: 'Outputs include images/video footage of the point of interest',
          supportedSiteTypes: ['area', 'corridor', 'asset'],
          execution: 'automatic',
          icon: POI_MISSION
        }
      ]
    : [])
]

export const SPECIFIC_MISSION_TYPES = [
  {
    text: 'Area Mapping',
    value: 'area_mapping',
    supportedSiteTypes: {
      SRTM: ['area', 'corridor'],
      Custom: ['area'],
      None: ['area']
    },
    icon: 'mdi-shape-polygon-plus'
  },
  {
    text: 'Corridor Mapping',
    value: 'corridor_mapping',
    supportedSiteTypes: {
      SRTM: ['area', 'corridor'],
      Custom: [],
      None: ['area', 'corridor']
    },
    icon: 'mdi-vector-polyline-plus'
  },
  {
    text: 'Corridor Video',
    value: 'corridor_video',
    supportedSiteTypes: {
      SRTM: ['area', 'corridor'],
      Custom: [],
      None: []
    },
    icon: 'mdi-vector-polyline-plus'
  },
  {
    text: 'Waypoint',
    value: 'waypoints',
    supportedSiteTypes: {
      SRTM: ['area', 'corridor'],
      Custom: ['area', 'corridor'],
      None: ['area', 'corridor', 'asset']
    },
    icon: 'mdi-vector-polyline-plus'
  },
  ...(POI_MISSIONS
    ? [
        {
          text: 'POI',
          value: 'poi',
          supportedSiteTypes: {
            SRTM: ['area', 'corridor'],
            Custom: ['area', 'corridor'],
            None: ['area', 'corridor', 'asset']
          },
          icon: 'mdi-map-marker-plus-outline'
        }
      ]
    : [])
]

export const FLIGHT_PATH_MODES = [
  {
    label: 'Curved',
    value: 'curved',
    description: `Drone will fly curved paths when passing a waypoint.
      Warning: Waypoint Actions are not supported in this mode!`
  },
  {
    label: 'Normal',
    value: 'normal',
    description: 'Drone will fly straight paths'
  }
]

export const MISSION_ACTIONS = {
  hover: {
    label: 'Hover',
    valueType: 'number',
    key: 'hover',
    valueSuffix: 's',
    validation: {
      min: 1,
      max: 32
    }
  },
  take_photo: {
    label: 'Take Photo',
    valueType: 'boolean',
    key: 'take_photo'
  },
  start_recording: {
    label: 'Start Recording',
    valueType: 'boolean',
    key: 'start_recording'
  },
  stop_recording: {
    label: 'Stop Recording',
    valueType: 'boolean',
    key: 'stop_recording'
  },
  rotate_drone: {
    label: 'Rotate Drone',
    valueType: 'number',
    key: 'rotate_drone',
    valueSuffix: ' deg.',
    validation: {
      min: -180,
      max: 180
    }
  },
  pitch_camera_gimbal: {
    label: 'Pitch Camera Gimbal',
    valueType: 'number',
    key: 'pitch_camera_gimbal',
    valueSuffix: ' deg.',
    validation: {
      min: -90,
      max: 0
    }
  }
}

export const HEADING_MODES = [
  {
    label: 'Auto',
    value: 'AUTO'
  },
  {
    label: 'Custom',
    value: 'USING_WAYPOINT_HEADING'
  }
]

export const MEDIA_CAPTURE_TYPES = [
  {
    label: 'Image',
    value: 'image'
  },
  {
    label: 'Video',
    value: 'video'
  }
]

export const SUPPORTED_MULTIPLE_PHOTO_TYPE_DRONES = ['Mavic 3T', 'Mavic 3M']

export const PHOTO_TYPES = {
  WIDE: 'wide',
  THERMAL: 'thermal',
  MULTISPECTRAL: 'multispectral'
}

export const PHOTO_TYPES_LABEL_MAP = {
  [PHOTO_TYPES.WIDE]: 'RGB',
  [PHOTO_TYPES.THERMAL]: 'Thermal',
  [PHOTO_TYPES.MULTISPECTRAL]: 'Multispectral'
}

export const MISSION_ERRORS = {
  EXCEEDED_MAX_MISSION_GENERATION_COUNT:
    'exceeded_max_mission_generation_count',
  EXCEEDED_MAX_POLYGON_MISSION_SIZE: 'exceeded_max_polygon_mission_size',
  EXCEEDED_MAX_LINESTRING_MISSION_SIZE: 'exceeded_max_linestring_mission_size'
}

export const MAX_TERRAIN_FOLLOW_SENSITIVITY_LEVEL = 10
export const MIN_TERRAIN_FOLLOW_SENSITIVITY_LEVEL = 1
export const DEFAULT_TERRAIN_FOLLOW_SENSITIVITY_LEVEL = 5

export const MAX_MISSION_COUNT = 75
